export default function BotonsAccesibilitat(props) {
    const { controlsAccessibles, accessKey } = props;

    return (
        <>
            {controlsAccessibles && (
                <div className='sr-only'>
                    Després de la publicitat pots interactuar amb el player amb els següents botons
                    <button
                        className='startstop'
                        title='Atura o engega el reproductor'
                        accessKey='p'
                        onClick={() => {
                            accessKey('play');
                        }}
                    >
                        Engegar/Aturar
                    </button>
                    <button
                        className='mute'
                        title='Mute on/off'
                        accessKey='s'
                        onClick={() => {
                            accessKey('mute');
                        }}
                    >
                        Silenciar
                    </button>
                    <button
                        className='incv'
                        title='Àudio més alt'
                        accessKey='5'
                        onClick={() => {
                            accessKey('volumeUp');
                        }}
                    >
                        Pujar el volum
                    </button>
                    <button
                        className='decv'
                        title='Àudio més baix'
                        accessKey='6'
                        onClick={() => {
                            accessKey('volumeDown');
                        }}
                    >
                        Disminuir el volum
                    </button>
                    <a href='//www.ccma.cat/atencio/accessibilitat/' className='sr-only' target='_blank' rel='noreferrer'>
                        Instruccions per interactuar amb el player
                    </a>
                </div>
            )}
        </>
    );
}
